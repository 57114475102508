.reports {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  padding: 4rem 6rem;

  @media screen and (max-width: 1150px) {
    padding: 2rem 4rem;
  }

  @media screen and (max-width: 550px) {
    padding: 2rem;
  }

  .reports__savemom {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 1rem;

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }

    .reports__savemom-img {
      width: 100%;
      height: 100%;

      flex: 0.5;
      display: flex;
      justify-content: center;

      img {
        width: 300px;
        height: 210px;
        border-radius: 6px;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        &:hover {
          box-shadow: -5px 5px 22px 5px #8888;
          transform: translate(2px, -2px);
        }

        @media screen and (max-width: 1150px) {
          width: 250px;
          height: 160px;
        }

        @media screen and (max-width: 800px) {
          width: 100%;
          height: 50vh;
        }

        @media screen and (max-width: 550px) {
          height: 25vh;
        }
      }
    }

    .reports__savemom-content {
      width: 100%;
      flex: 1;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      @media screen and (max-width: 1150px) {
        padding-left: 1rem;
      }

      @media screen and (max-width: 800px) {
        padding: 1rem 0 12px 1rem;
      }

      h6 {
        color: #00877c;

        @media screen and (max-width: 550px) {
          font-size: 0.8rem;
        }
      }

      h4 {
        @media screen and (max-width: 1150px) {
          font-size: 1.2rem;
        }

        @media screen and (max-width: 550px) {
          font-size: 1rem;
        }
      }

      p {
        color: #0000008f;
        font-weight: 500;
        margin-top: 20px;

        @media screen and (max-width: 1150px) {
          margin-top: 5px;
        }

        @media screen and (max-width: 550px) {
          font-size: 12px;
        }
      }

      .read-btn {
        padding: 6px 1.2rem;
        border: 1px solid #00877c;
        color: #00877c;
        background-color: #fff;
        transition: 0.3s all ease;
        border-radius: 5px;
        font-weight: 500;
        box-shadow: 0 0 4px #8888;

        &:hover {
          color: #fff;
          background-color: #00877c;
        }

        @media screen and (max-width: 600px) {
          padding: 3px 8px;
          font-size: 12px;
        }
      }
    }
  }
}

.page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0 3rem;

  span {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #9b96962c;
    text-align: center;
    margin: 5px;
    cursor: pointer;

    &.active {
      background-color: #00877c;
      color: #fff;
    }
  }
}
