.home__caro img {
  padding-top: 5rem;
  width: 100%;
  height: 95vh;

  @media screen and (max-width: 800px) {
    height: 70vh;
  }

  @media screen and (max-width: 500px) {
    height: 50vh;
  }
}

.caro-caption {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: -40px;
  left: 103px;
  text-align: left;

  width: 48%;

  @media screen and (max-width: 1150px) {
    width: 60%;
    top: 80px;
    left: 40px;
  }

  @media screen and (max-width: 800px) {
    width: 65%;
    top: 80px;
    left: 30px;
  }

  @media screen and (max-width: 550px) {
    width: 65%;
    top: 60px;
    left: 20px;
  }
}

.home__content-2 {
  background-image: url('../../assets//thirdbannerimg.png');
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 600px) {
    height: 43vh;
  }

  div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    width: 80%;
    height: 100%;

    // padding-top: 4rem;

    @media screen and (max-width: 1000px) {
      width: 90%;
    }
  }
}

.home__content-4 {
  background-image: url('../../assets//fifthbanner.jpg');
  width: 100%;
  height: 90vh;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 800px) {
    height: 60vh;
  }

  @media screen and (max-width: 550px) {
    height: 43vh;
  }

  div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    width: 65%;
    height: 100%;

    // padding-top: 4rem;

    @media screen and (max-width: 1000px) {
      width: 95%;
    }
  }
}
