@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --color-grey: #00877c;
  --color-white: #ffff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: 'Manrope', sans-serif;
}
