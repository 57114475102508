.product__padding {
  background-color: transparent;
  width: 100%;
  height: 60vh;

  @media screen and (max-width: 1150px) {
    height: 55vh;
  }

  @media screen and (max-width: 800px) {
    height: 62vh;
  }

  @media screen and (max-width: 550px) {
    height: 50vh;
  }

  @media screen and (max-width: 475px) {
    height: 53vh;
  }

  @media screen and (max-width: 370px) {
    height: 57vh;
  }

  .product__savemom {
    background-image: url('../../assets/achievementbanner.png');
    background-size: cover;
    background-repeat: no-repeat;

    position: absolute;
    width: 100%;
    height: 60vh;

    @media screen and (max-width: 1150px) {
      height: 55vh;
    }

    @media screen and (max-width: 800px) {
      height: 40vh;
      top: 5%;
    }

    @media screen and (max-width: 550px) {
      height: 33vh;
    }

    @media screen and (max-width: 450px) {
      top: 3%;
      height: 25vh;
    }
    @media screen and (max-width: 380px) {
      top: 6%;
      height: 25vh;
    }
  }

  .product__savemom-content {
    margin: 5rem 0;
    width: 60%;

    @media screen and (max-width: 1150px) {
      width: 75%;
    }

    @media screen and (max-width: 800px) {
      width: 80%;
      margin: 5rem 0 0 2rem;
    }

    @media screen and (max-width: 600px) {
      width: 90%;
      margin: 3.5rem 0 0 1rem;
    }

    @media screen and (max-width: 450px) {
      margin: 3.5rem 0 0 1rem;
    }

    @media screen and (max-width: 380px) {
      margin: 1.5rem 0 0 1rem;
    }
  }

  p {
    font-weight: 500;

    @media screen and (max-width: 800px) {
      color: black;
      margin-top: 3rem;
    }

    @media screen and (max-width: 550px) {
      font-size: 14px;
    }

    @media screen and (max-width: 450px) {
      margin-top: 3.5rem;
    }
  }

  h3 {
    margin-bottom: 0;

    @media screen and (max-width: 800px) {
      font-size: 2.3rem;
    }

    @media screen and (max-width: 550px) {
      font-size: 2rem;
    }

    @media screen and (max-width: 450px) {
      font-size: 1.7rem;
    }
  }
}

// ---------- CONTENT - 1 ---------------
.product__content-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 4rem 6rem;

  @media screen and (max-width: 800px) {
    padding: 4rem;
  }
  @media screen and (max-width: 550px) {
    padding: 2rem;
  }

  .product__content-1_head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .head__line {
      width: 80px;
      height: 2px;
      background-color: #00877c;
    }

    p {
      color: #000000a0;
      padding: 2rem 0 0;
    }
  }

  .content-1_mobile {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    // text-align: center;
    // flex-direction: row;
    max-width: 75%;
    position: relative;
    // display: flex;
    // align-items: center;
    // flex-direction: column;

    @media screen and (max-width: 1150px) {
      max-width: 100%;
    }

    .content-1_mobile_container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      padding-top: 5rem;

      width: max-content;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @media screen and (max-width: 800px) {
        padding-top: 4rem;
      }

      @media screen and (max-width: 550px) {
        padding-top: 2rem;
      }
    }

    .content-1_mobile-pics {
      position: relative;
      min-width: 160px;
      // height: 50vh;
      margin: 0 2rem 12px 12px;

      @media screen and (max-width: 800px) {
        min-width: 120px;
        margin-right: 0;
      }
      @media screen and (max-width: 550px) {
        min-width: 80px;
      }

      img {
        width: 100%;
        height: auto;
        object-fit: cover;
        opacity: 1;
        box-shadow: -5px 3px 16px 2px rgb(0 0 0 / 0.2);

        &:hover {
          // transform: translatex(10px);
          transform: translate(6px, -6px);
          // padding-top: 10px;
        }

        @media screen and (max-width: 800px) {
          width: 90%;
        }
        @media screen and (max-width: 550px) {
          width: 90%;
        }
      }
    }
  }

  .content-1_mobile-arrors {
    padding-top: 2rem;

    .img__arror-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: #fff;
      background-color: #00877c;
      margin: 0 8px;
      cursor: pointer;

      @media screen and (max-width: 550px) {
        width: 20px;
        height: 20px;
      }
    }

    @media screen and (max-width: 550px) {
      padding-top: 1rem;
    }
  }
}

// ---------- CONTENT - 2 -------------
.product__content-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00877c;

  padding: 3rem 6rem;

  @media screen and (max-width: 1150px) {
    padding: 3rem 4rem;
  }

  @media screen and (max-width: 800px) {
    padding: 2rem;
  }

  @media screen and (max-width: 550px) {
  }

  h3 {
    font-size: 1.8rem;
    padding-right: 20rem;

    @media screen and (max-width: 1150px) {
      font-size: 1.4rem;
      padding-right: 15rem;
    }

    @media screen and (max-width: 800px) {
      font-size: 1.2rem;
      letter-spacing: 4px;
      padding-right: 10rem;
    }

    @media screen and (max-width: 550px) {
      font-size: 13px;
      letter-spacing: 3px;
      padding-right: 4rem;
    }
  }

  img {
    width: auto;
    height: 3.5rem;

    @media screen and (max-width: 800px) {
      height: 2.5rem;
    }

    @media screen and (max-width: 550px) {
      height: 2rem;
    }
  }
}

// ----------- CONTENT - 3 -----------------
.product__content-3 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 4rem 6rem;

  @media screen and (max-width: 1150px) {
    padding: 4rem;
  }
  @media screen and (max-width: 800px) {
    padding: 4rem;
  }
  @media screen and (max-width: 550px) {
    padding: 2rem 2rem 0;
  }

  h3 {
    padding: 1rem 0 0.5rem;
  }

  .head__line {
    width: 80px;
    height: 2px;
    background-color: #00877c;

    margin-bottom: 4rem;

    @media screen and (max-width: 400px) {
      margin-bottom: 3rem;
    }
  }

  .product__content-3_info {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card {
    margin-bottom: 4rem;
    box-shadow: -5px 5px 8px #aaaa;
    cursor: pointer;

    &:hover {
      transform: translateY(-10px);
      box-shadow: -7px 7px 18px 2px #aaaa;

      @media screen and (max-width: 575px) {
        transform: none;
      }
    }

    @media screen and (max-width: 575px) {
      margin-bottom: 2rem;
    }
  }

  .card-text {
    padding-top: 10px;
    font-weight: 500;
    letter-spacing: 0.04em;
    line-height: 28px;
    word-spacing: 0.04em;
    color: #00000097;

    @media screen and (max-width: 1150px) {
      font-size: 14px;
      padding-top: 6px;
      line-height: 24px;
    }
  }
}

// ---------- CONTENT - 4 -----------------
.product__content-4 {
  .section__padding {
    @media screen and (max-width: 550px) {
      padding: 2rem;
    }
  }

  .app__wrapper_img {
    @media screen and (max-width: 550px) {
      margin: 1rem 0 0 0;
    }

    .product__caro {
      margin-right: 2rem;
      box-shadow: 0px 0px 20px 3px #aaaa;
      border: 2px solid rgb(231, 223, 223);
    }

    // img {
    //   width: 90%;
    //   height: auto;

    //   @media screen and (max-width: 1150px) {
    //     width: 100%;
    //     padding-bottom: 2rem;
    //   }
    //   @media screen and (max-width: 550px) {
    //     padding-bottom: 0;
    //   }
    // }
  }

  .app__wrapper_info {
    p {
      color: #00000097;
    }

    @media screen and (max-width: 1150px) {
      padding-top: 2rem;
    }

    @media screen and (max-width: 800px) {
      padding-top: 2rem;
    }
  }
}

// ---------- CONTENT - 5 -----------------
.product__content-5 {
  .section__padding {
    padding: 0rem 6rem;

    .product__caro {
      margin-right: 2rem;
      box-shadow: 0px 0px 20px 3px #aaaa;
      border: 2px solid rgb(231, 223, 223);
    }

    @media screen and (max-width: 1150px) {
      padding: 4rem;
    }

    @media screen and (max-width: 800px) {
      padding: 2rem;
    }

    @media screen and (max-width: 550px) {
      padding: 0 2rem;
    }
  }

  .app__wrapper {
    @media screen and (max-width: 1150px) {
      flex-direction: column-reverse;
    }
  }

  .app__wrapper_img {
    justify-content: flex-end;
    @media screen and (max-width: 550px) {
      margin: 0;
    }

    img {
      width: 90%;
      height: auto;

      @media screen and (max-width: 1150px) {
        width: 100%;
        // padding-bottom: 2rem;
      }
      @media screen and (max-width: 550px) {
        // padding-bottom: 2rem;
      }
    }
  }

  .app__wrapper_info {
    align-items: flex-end;
    text-align: end;
    p {
      color: #00000097;
    }
  }
}

// ---------- CONTENT - 6 -------------
.product__content-6 {
  background-color: #f4f4f4;
  padding: 4rem 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media screen and (max-width: 1150px) {
    padding: 4rem;
  }

  @media screen and (max-width: 800px) {
    padding: 3rem 2rem;
  }

  @media screen and (max-width: 550px) {
    padding: 2rem;
  }

  .head-line {
    width: 80px;
    height: 2px;
    background-color: #00877c;
  }

  .product__content-6_info {
    display: flex;
    justify-content: space-between;
    align-items: center;

    min-height: 50vh;

    padding-top: 4rem;

    @media screen and (max-width: 1150px) {
      flex-direction: column;
    }

    @media screen and (max-width: 1150px) {
      padding-top: 2rem;
    }
  }

  .box {
    padding: 5rem 0;
    margin: 2rem 1rem;
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 6px;
    box-shadow: -8px 8px #8888;

    text-align: center;
    background-color: #fff;
    cursor: pointer;

    &:hover {
      transform: translate(-8px, 8px);
      box-shadow: -3px 3px 14px #8888;
    }

    @media screen and (max-width: 550px) {
      padding: 2rem 0;
      margin: 1rem;

      box-shadow: -6px 6px #8888;
    }

    .box-content {
      width: 90%;
      // height: auto;
      display: flex;
      align-items: center;
      flex-direction: column;

      .content-2_icon {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        background-color: #00877c;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      p {
        color: #00000097;
        font-weight: 500;

        @media screen and (max-width: 550px) {
          padding-top: 1.5rem;
        }
      }
    }
  }
}
