.section__padding {
  padding: 4rem 6rem;

  @media screen and (max-width: 1150px) {
    padding: 4rem;
  }

  @media screen and (max-width: 800px) {
    padding: 3rem 2rem;
  }

  @media screen and (max-width: 550px) {
    padding: 2rem 1rem;
  }
}

a {
  color: unset;
  text-decoration: none;
}

.head-text {
  font-size: 2.5rem;
  letter-spacing: 6px;
  line-height: 1.5em;
  font-weight: 500;
  color: var(--color-white);

  @media screen and (max-width: 1150px) {
    font-size: 1.9rem;
    letter-spacing: 5px;
  }

  @media screen and (max-width: 800px) {
    font-size: 1.5rem;
    letter-spacing: 4.5px;
  }

  @media screen and (max-width: 550px) {
    font-size: 1.1rem;
    letter-spacing: 3px;
  }
}

.sub__head-text {
  font-size: 24px;
  color: #000;
  font-weight: 500;

  @media screen and (max-width: 800px) {
    font-size: 20px;
  }

  @media screen and (max-width: 550px) {
    color: #00877c;
    font-size: 18px;
  }
}

.p-text {
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 33px;
  color: var(--color-white);
  padding: 2rem 0 1rem;

  @media screen and (max-width: 1150px) {
    font-size: 17px;
    line-height: 30px;
  }

  @media screen and (max-width: 800px) {
    font-size: 16px;
    line-height: 28px;
  }

  @media screen and (max-width: 550px) {
    font-size: 13px;
    letter-spacing: 0.03em;
    line-height: 23px;
    padding: 0.5rem 0 0;
  }
}

.btn {
  margin-top: 0.5rem;
  font-weight: 500;

  @media screen and (max-width: 800px) {
    font-size: 14px;
    padding: 8px 14px;
    margin-top: 0.5rem;
  }

  @media screen and (max-width: 550px) {
    font-size: 12px;
    padding: 6px 14px;
    margin-top: 0.3rem;
  }
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 55vh;

  @media screen and (max-width: 1150px) {
    flex-direction: column;
  }
}

.app__wrapper-contact {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  min-height: 55vh;

  @media screen and (max-width: 1150px) {
    flex-direction: column;
  }
}

.app__wrapper_info {
  flex: 0.8;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  text-align: start;
}

.app__wrapper_img {
  flex: 0.8;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;

  @media screen and (max-width: 1150px) {
    margin: 5rem 0 0 0;
  }
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;

  @media screen and (max-width: 1150px) {
    margin: 0 0 5rem 0;
  }
}

.app__flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
