.ourjourney__padding {
  width: 100%;
  height: 60vh;

  @media screen and (max-width: 1150px) {
    height: 55vh;
  }

  @media screen and (max-width: 800px) {
    height: 62vh;
  }

  @media screen and (max-width: 550px) {
    height: 50vh;
  }

  @media screen and (max-width: 475px) {
    height: 53vh;
  }

  @media screen and (max-width: 370px) {
    height: 57vh;
  }

  .ourjourney__journey {
    background-image: url('../../assets/achievementbanner.png');
    background-size: cover;
    background-repeat: no-repeat;

    position: absolute;
    width: 100%;
    height: 60vh;

    @media screen and (max-width: 1150px) {
      height: 55vh;
    }

    @media screen and (max-width: 800px) {
      height: 40vh;
      top: 5%;
    }

    @media screen and (max-width: 550px) {
      height: 33vh;
    }

    @media screen and (max-width: 450px) {
      top: 3%;
      height: 25vh;
    }

    @media screen and (max-width: 380px) {
      top: 5%;
      height: 25vh;
    }
  }

  .ourjourney__journey-content {
    margin: 5rem 0;
    width: 60%;

    @media screen and (max-width: 1150px) {
      width: 75%;
    }

    @media screen and (max-width: 800px) {
      width: 80%;
      margin: 5rem 0 0 2rem;
    }

    @media screen and (max-width: 600px) {
      width: 90%;
      margin: 3.5rem 0 0 1rem;
    }

    @media screen and (max-width: 450px) {
      margin: 3.5rem 0 0 1rem;
    }

    @media screen and (max-width: 380px) {
      margin: 1.5rem 0 0 1rem;
    }
  }

  p {
    font-weight: 500;

    @media screen and (max-width: 800px) {
      color: black;
      margin-top: 3rem;
    }

    @media screen and (max-width: 550px) {
      font-size: 14px;
    }

    @media screen and (max-width: 450px) {
      margin-top: 3.5rem;
    }
  }

  h3 {
    @media screen and (max-width: 800px) {
      font-size: 2.3rem;
    }

    @media screen and (max-width: 550px) {
      font-size: 2rem;
    }

    @media screen and (max-width: 450px) {
      font-size: 1.7rem;
    }
  }
}

// --------- CONTENT - 1 -------------
.ourjourney__content-1 {
  padding: 3rem 0;

  @media screen and (max-width: 550px) {
    padding: 0;
  }

  .timeline {
    position: relative;
    max-width: 1000px;
    margin: 0 auto;

    @media screen and (max-width: 600px) {
      // max-width: au;

      /* Make sure that all arrows are pointing leftwards */
      .container::before {
        left: 61px;
        border: medium solid #00877c;
        border-width: 10px 10px 10px 0;
        border-color: transparent #00877c transparent transparent;
      }

      /* Make sure all circles are at the same spot */
      .left::after,
      .right::after {
        left: 15px;
      }

      /* Make all right containers behave like the left ones */
      .right {
        left: 0%;
      }

      /* Reset left container positioning */
      .left {
        left: auto;
      }
    }
  }

  /* The actual timeline (the vertical ruler) */
  .timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #000;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;

    @media screen and (max-width: 600px) {
      /* Place the timeline to the left */
      left: 28px;
    }
  }

  /* Container around content */
  .container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;

    @media screen and (max-width: 600px) {
      /* Full-width containers */
      width: 100%;
      padding-left: 70px;
      padding-right: 25px;
      padding-bottom: 1rem;
    }
  }

  /* The circles on the timeline */
  .container::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -17px;
    background-color: white;
    border: 4px solid #00877c;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }

  /* Place the container to the left */
  .left {
    left: -25.5%;
  }

  /* Place the container to the right */
  .right {
    left: 25.5%;
  }

  /* Add arrows to the left container (pointing right) */
  .left::before {
    content: ' ';
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid #00877c;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent #00877c;
  }

  /* Add arrows to the right container (pointing left) */
  .right::before {
    content: ' ';
    height: 0;
    position: absolute;
    top: 22px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid #00877c;
    border-width: 10px 10px 10px 0;
    border-color: transparent #00877c transparent transparent;
  }

  /* Fix the circle for containers on the right side */
  .right::after {
    left: -16px;
  }

  /* The actual content */
  .content {
    padding: 20px 30px;
    background-color: #00877c;
    position: relative;
    border-radius: 6px;

    h3 {
      color: #fff;

      @media screen and (max-width: 600px) {
        font-size: 1.3rem;
      }
      @media screen and (max-width: 550px) {
        font-size: 1.2rem;
      }
      @media screen and (max-width: 380px) {
        font-size: 1.1rem;
      }
    }

    p {
      color: #fff;

      @media screen and (max-width: 600px) {
        font-size: 14px;
      }
      @media screen and (max-width: 380px) {
        font-size: 13px;
      }
    }
  }
}

// --------- CONTENT - 2 --------------
.ourjourney__content-2 {
  background-color: #f4f4f4;
  margin: 2rem 0 0;
  padding: 3rem 0;

  @media screen and (max-width: 1150px) {
    padding: 0 0 2rem;
  }

  @media screen and (max-width: 550px) {
    padding: 0;
  }

  .app__wrapper {
    padding: 0 6rem;

    @media screen and (max-width: 1150px) {
      padding: 0 4rem;
    }

    @media screen and (max-width: 800px) {
      padding: 0 2rem;
    }

    @media screen and (max-width: 550px) {
      padding: 0 1rem;
    }
  }

  .app__wrapper_img {
    @media screen and (max-width: 1150px) {
      margin: 3rem 0 0 0;
    }

    img {
      width: 60%;
      height: 60%;

      @media screen and (max-width: 550px) {
        width: 270px;
      }
    }
  }

  .app__wrapper_info {
    @media screen and (max-width: 1150px) {
      padding-top: 2rem;
    }

    @media screen and (max-width: 550px) {
      padding: 2rem 0;
    }

    h3 {
      padding: 2rem 0 0.2rem;

      @media screen and (max-width: 800px) {
        padding: 1rem 0 8px;
      }
    }

    .head-line {
      width: 80px;
      height: 2px;
      background-color: #00877c;
      margin: 0 2rem 2rem;

      @media screen and (max-width: 800px) {
        margin: 0 1rem 2rem;
      }
    }

    li {
      font-size: 16px;
      padding: 8px 0;

      @media screen and (max-width: 1150px) {
        font-size: 16px;
        line-height: 30px;
      }

      @media screen and (max-width: 800px) {
        font-size: 15px;
        padding: 6px 0;
      }

      @media screen and (max-width: 450px) {
        font-size: 13px;
        padding: 0 0 10px;
        line-height: 26px;
      }
    }
  }
}
