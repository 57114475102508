.nav-link {
  font-weight: 600;
}

.nav-item {
  padding-left: 0.7rem;
  padding-right: 0.7rem;
}

@media screen and (max-width: 550px) {
  .navbar {
    padding: 0.5rem 1.5rem;
  }
}
