.news__padding {
  background-color: transparent;
  width: 100%;
  height: 60vh;

  @media screen and (max-width: 1150px) {
    height: 55vh;
  }

  @media screen and (max-width: 800px) {
    height: 62vh;
  }

  @media screen and (max-width: 550px) {
    height: 50vh;
  }

  @media screen and (max-width: 475px) {
    height: 53vh;
  }

  @media screen and (max-width: 370px) {
    height: 57vh;
  }

  .news__savemom {
    background-image: url('../../assets/achievementbanner.png');
    background-size: cover;
    background-repeat: no-repeat;

    position: absolute;
    width: 100%;
    height: 60vh;

    @media screen and (max-width: 1150px) {
      height: 55vh;
    }

    @media screen and (max-width: 800px) {
      height: 40vh;
      top: 5%;
    }

    @media screen and (max-width: 550px) {
      height: 33vh;
    }

    @media screen and (max-width: 450px) {
      top: 3%;
      height: 25vh;
    }
    @media screen and (max-width: 380px) {
      top: 7%;
      height: 25vh;
    }
  }

  .news__savemom-content {
    margin: 5rem 0;
    width: 60%;

    @media screen and (max-width: 1150px) {
      width: 75%;
    }

    @media screen and (max-width: 800px) {
      width: 80%;
      margin: 5rem 0 0 2rem;
    }

    @media screen and (max-width: 600px) {
      width: 90%;
      margin: 3.5rem 0 0 1rem;
    }

    @media screen and (max-width: 450px) {
      margin: 3.5rem 0 0 1rem;
    }

    @media screen and (max-width: 380px) {
      margin: 1.5rem 0 0 1rem;
    }
  }

  p {
    font-weight: 500;

    @media screen and (max-width: 800px) {
      color: black;
      margin-top: 3rem;
    }

    @media screen and (max-width: 550px) {
      font-size: 14px;
    }

    @media screen and (max-width: 450px) {
      margin-top: 3.5rem;
    }

    @media screen and (max-width: 380px) {
      margin-top: 4rem;
    }
  }

  h3 {
    margin-bottom: 0;

    @media screen and (max-width: 800px) {
      font-size: 2.3rem;
    }

    @media screen and (max-width: 550px) {
      font-size: 2rem;
    }

    @media screen and (max-width: 450px) {
      font-size: 1.7rem;
    }
  }
}

// ------------ CONTENT - 1 --------------
.news__content-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 4rem 6rem;

  h3 {
    font-size: 1.7rem;
    letter-spacing: 0.04em;
    text-align: center;
  }

  .head-line {
    width: 55px;
    height: 2px;
    background-color: #00877c;
  }

  .app__news-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    padding-top: 2rem;

    .app__news-item {
      width: 290px;
      flex-direction: column;

      margin: 2rem;
      padding: 1rem;
      border-radius: 0.5rem;
      background-color: #fff;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      color: #000;

      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        box-shadow: -5px 5px 25px rgba(0, 0, 0, 0.2);
      }

      @media screen and (min-width: 2000px) {
        width: 470px;
        padding: 1.25rem;
        border-radius: 0.75rem;
      }

      @media screen and (max-width: 300px) {
        width: 100%;
        margin: 1rem;
      }
    }
  }

  .app__news-img {
    width: 100%;
    height: 230px;

    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.5rem;
    }

    @media screen and (min-width: 2000px) {
      height: 350px;
    }
  }

  .app__news-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;

    h6 {
      margin-top: 1rem;
      font-size: 12px;
      color: #00877c;
    }

    h4 {
      font-size: 1.2rem;
      font-weight: 700;
      line-height: 1.5;

      @media screen and (min-width: 2000px) {
        margin-top: 3rem;
      }
    }

    p {
      font-size: 14px;
      color: #000;
    }

    small {
      font-weight: 600;
      text-decoration: underline;
    }
  }
}

.more-btn {
  padding: 6px 1.2rem;
  border: 1px solid #00877c;
  color: #00877c;
  background-color: #fff;
  transition: 0.3s all ease;
  border-radius: 5px;
  font-weight: 500;

  &:hover {
    color: #fff;
    background-color: #00877c;
  }
}

// ----------- CONTENT - 2 --------------
.app__content-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem 6rem;

  @media screen and (max-width: 800px) {
    padding: 2rem 4rem;
  }
  @media screen and (max-width: 550px) {
    padding: 2rem;
  }

  .head-line {
    width: 70px;
    height: 2px;
    background-color: #00877c;
  }

  .app__work-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    margin: 4rem 0 2rem;

    @media screen and (max-width: 600px) {
      margin: 3rem 0;
    }
    @media screen and (max-width: 400px) {
      margin: 3rem 0 0;
    }

    .app__work-filter-item {
      padding: 0.4rem 1rem;
      border-radius: 0.5rem;
      // background-color: #f1ecec97;
      border: 1px solid #00877c;
      color: #000;
      font-weight: 700;
      transition: all 0.3s ease;
      cursor: pointer;

      margin: 0.5rem;

      &:hover {
        background-color: #00877c;
        color: #fff;
      }

      @media screen and (min-width: 2000px) {
        padding: 1rem 2rem;
        border-radius: 0.85rem;
      }

      @media screen and (max-width: 600px) {
        margin: 6px 4px;
      }
    }

    .work-text {
      font-size: 14px;

      @media screen and (max-width: 600px) {
        font-size: 12px;
      }
    }

    .item-active {
      background-color: #00877c;
      color: #fff;
    }
  }

  .app__work-portfolio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 2rem 0;
    width: 100%;

    @media screen and (max-width: 600px) {
      padding: 1rem 0;
    }

    .app__content-2_work {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      @media screen and (max-width: 800px) {
        width: 400px;
        height: 50vh;
      }

      @media screen and (max-width: 500px) {
        align-items: center;
      }

      .app__content-2_img {
        width: 100%;

        img {
          height: auto;
          border-radius: 6px;
          box-shadow: -3px 3px 12px #8888;

          &:hover {
            transform: translate(2px, -4px);
            box-shadow: -5px 5px 22px #8888;
          }

          @media screen and (max-width: 1000px) {
            width: 200px;
          }

          @media screen and (max-width: 800px) {
            width: 400px;
            height: 50vh;
          }

          @media screen and (max-width: 500px) {
            width: 100%;
            height: auto;
          }
        }
      }

      h5 {
        font-size: 18px;
        padding: 1rem 0.5rem;
        color: #00000077;

        @media screen and (max-width: 500px) {
          font-size: 16px;
        }
      }
    }
  }
}
