.whoweare__padding {
  background-color: transparent;
  width: 100%;
  height: 60vh;

  @media screen and (max-width: 1150px) {
    height: 55vh;
  }

  @media screen and (max-width: 800px) {
    height: 62vh;
  }

  @media screen and (max-width: 550px) {
    height: 50vh;
  }

  @media screen and (max-width: 475px) {
    height: 53vh;
  }

  @media screen and (max-width: 370px) {
    height: 57vh;
  }

  .whoweare__about {
    background-image: url('../../assets/achievementbanner.png');
    background-size: cover;
    background-repeat: no-repeat;

    position: absolute;
    width: 100%;
    height: 60vh;

    @media screen and (max-width: 1150px) {
      height: 55vh;
    }

    @media screen and (max-width: 800px) {
      height: 40vh;
      top: 5%;
    }

    @media screen and (max-width: 550px) {
      height: 33vh;
    }

    @media screen and (max-width: 450px) {
      top: 3%;
      height: 25vh;
    }
    @media screen and (max-width: 380px) {
      top: 5%;
      height: 25vh;
    }
  }

  .whoweare__about-content {
    margin: 5rem 0;
    width: 60%;

    @media screen and (max-width: 1150px) {
      width: 75%;
    }

    @media screen and (max-width: 800px) {
      width: 80%;
      margin: 5rem 0 0 2rem;
    }

    @media screen and (max-width: 600px) {
      width: 90%;
      margin: 3.5rem 0 0 1rem;
    }

    @media screen and (max-width: 450px) {
      margin: 3.5rem 0 0 1rem;
    }

    @media screen and (max-width: 380px) {
      margin: 1.5rem 0 0 1rem;
    }
  }

  p {
    font-weight: 500;

    @media screen and (max-width: 800px) {
      color: black;
      margin-top: 3rem;
    }

    @media screen and (max-width: 550px) {
      font-size: 14px;
    }

    @media screen and (max-width: 450px) {
      margin-top: 3.5rem;
    }
  }

  h3 {
    @media screen and (max-width: 800px) {
      font-size: 2.3rem;
    }

    @media screen and (max-width: 550px) {
      font-size: 2rem;
    }

    @media screen and (max-width: 450px) {
      font-size: 1.7rem;
    }
  }
}

// -------- CONTENT - 1 -----------
.whoweare__content-1 {
  // margin-top: 24rem;

  div {
    display: flex;
    align-items: center;
  }

  .app__wrapper_info {
    padding: 0 2rem;
    text-align: center;

    @media screen and (max-width: 450px) {
      padding: 0;
    }

    h3 {
      padding: 1rem 0;

      @media screen and (max-width: 800px) {
        padding: 1rem 0 8px;
      }
    }

    p {
      color: gray;
      font-size: 16px;
      padding: 8px 0;

      @media screen and (max-width: 800px) {
        font-size: 14px;
        padding: 6px 0;
      }

      @media screen and (max-width: 450px) {
        font-size: 13px;
        padding-bottom: 12px;
      }
    }

    img {
      @media screen and (max-width: 450px) {
        width: 63px;
        height: 60px;
      }
    }
  }
}

// -------- CONTENT - 2 -----------
.whoweare__content-2 {
  .section__padding {
    padding: 2rem 4rem 0;

    @media screen and (max-width: 1150px) {
      padding: 4rem;
    }

    @media screen and (max-width: 800px) {
      padding: 3rem 2rem;
    }

    @media screen and (max-width: 550px) {
      padding: 2rem 1rem;
    }

    .app__wrapper {
      min-height: 0;
      align-items: flex-start;
    }

    .app__wrapper_info {
      align-items: center;
      text-align: center;

      h3 {
        color: #fff;
        padding-top: 1rem;
      }

      p {
        color: #ffffffae;
        padding: 1rem;
      }
    }
  }

  .content-2_icon {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

// -------- CONTENT - 3 -----------
.whoweare__content-3 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .head-line {
    width: 80px;
    height: 2px;
    background-color: #00877c;
  }

  .content-3_youtube {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    text-align: center;
    // flex-direction: row;
    max-width: 96%;
    position: relative;
    // display: flex;
    // align-items: center;
    // flex-direction: column;

    @media screen and (max-width: 1150px) {
      max-width: 100%;
    }

    .content-3_youtube_container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      padding-top: 5rem;

      width: max-content;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @media screen and (max-width: 550px) {
        padding-top: 3rem;
      }
    }

    .content-3_youtube-video {
      position: relative;
      min-width: 390px;
      height: 280px;
      margin-right: 3rem;
      // box-shadow: 1px 1px 1px #888;

      @media screen and (max-width: 550px) {
        min-width: 210px;
        height: 150px;
        margin-right: 8px;
      }

      iframe {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
      }
    }
  }

  .content-3_youtube-arrors {
    padding-top: 2rem;

    .video__arror-icon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: #fff;
      background-color: #00877c;
      margin: 0 8px;
    }
  }
}

// -------- CONTENT - 4 -----------
.whoweare__content-4 {
  .whoweare__content-4_info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    text-align: center;
  }

  .whoweare__content-4_info-count {
    padding: 0 5rem;

    @media screen and (max-width: 1150px) {
      padding: 1rem 3rem;
    }

    @media screen and (max-width: 550px) {
      padding: 0.5rem 2rem;
    }

    h1 {
      color: #fff;
      // text-align: center;
    }

    p {
      color: #fff;
      font-size: 18px;
      letter-spacing: 0.04em;

      @media screen and (max-width: 550px) {
        font-size: 15px;
      }
    }
  }
}

// -------- CONTENT - 5 -----------
.whoweare__content-5 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .head-line {
    width: 80px;
    height: 2px;
    background-color: #00877c;
  }

  h3 {
    margin-top: 3.5rem;

    @media screen and (max-width: 550px) {
      margin-top: 2rem;
    }
  }

  .content-5_brand {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    text-align: center;
    // flex-direction: row;
    max-width: 100%;
    position: relative;

    .content-5_brand_container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: row;
      padding-top: 5rem;

      width: max-content;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      @media screen and (max-width: 550px) {
        padding-top: 2.5rem;
      }
    }

    .content-5_brand-img {
      position: relative;
      min-width: 200px;
      height: 200px;
      margin-right: 3rem;

      @media screen and (max-width: 550px) {
        min-width: 100px;
        height: 170px;
        margin-right: 2rem;
      }

      img {
        width: 160px;
        height: 90px;

        @media screen and (max-width: 550px) {
          width: 80px;
          height: 60px;
        }
      }

      p {
        font-size: 14px;
        padding-top: 1rem;
        line-height: 20px;

        @media screen and (max-width: 550px) {
          font-size: 12px;
          line-height: 1.2em;
        }
      }
    }
  }
}

// --------- CONTENT - 7 ---------------
.whoweare__content-7 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  // margin-top: 3rem;

  .head-line {
    width: 80px;
    height: 2px;
    background-color: #00877c;
  }

  h3 {
    margin-top: 3.5rem;

    @media screen and (max-width: 550px) {
      margin-top: 2rem;
    }
  }

  .content-7_partner {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 0 2rem;
    // text-align: center;
    // flex-direction: row;
    // max-width: 100%;
    // position: relative;

    @media screen and (max-width: 550px) {
      padding: 2.5rem 0 2rem;
    }

    .content-7_partner-img {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      // position: relative;
      // min-width: 200px;
      // height: 200px;
      padding: 0 3rem;

      @media screen and (max-width: 550px) {
        // min-width: 100px;
        // height: 150px;
        padding: 0 1rem;
      }

      img {
        width: 120px;
        height: 100px;

        @media screen and (max-width: 550px) {
          width: 50px;
          height: 40px;
        }
      }

      p {
        font-size: 13px;
        padding-top: 1rem;
        line-height: 23px;

        @media screen and (max-width: 550px) {
          font-size: 11px;
          line-height: 1.2em;
        }
      }
    }
  }
}
