.getInvolved__padding {
  background-color: transparent;
  width: 100%;
  height: 60vh;

  @media screen and (max-width: 1150px) {
    height: 55vh;
  }

  @media screen and (max-width: 800px) {
    height: 62vh;
  }

  @media screen and (max-width: 550px) {
    height: 50vh;
  }

  @media screen and (max-width: 475px) {
    height: 53vh;
  }

  @media screen and (max-width: 370px) {
    height: 57vh;
  }

  .getInvolved__savemom {
    background-image: url('../../assets/achievementbanner.png');
    background-size: cover;
    background-repeat: no-repeat;

    position: absolute;
    width: 100%;
    height: 60vh;

    @media screen and (max-width: 1150px) {
      height: 55vh;
    }

    @media screen and (max-width: 800px) {
      height: 40vh;
      top: 5%;
    }

    @media screen and (max-width: 550px) {
      height: 33vh;
    }

    @media screen and (max-width: 450px) {
      top: 3%;
      height: 25vh;
    }
    @media screen and (max-width: 380px) {
      top: 5%;
      height: 25vh;
    }
  }

  .getInvolved__savemom-content {
    margin: 5rem 0;
    width: 60%;

    @media screen and (max-width: 1150px) {
      width: 75%;
    }

    @media screen and (max-width: 800px) {
      width: 80%;
      margin: 5rem 0 0 2rem;
    }

    @media screen and (max-width: 600px) {
      width: 90%;
      margin: 3.5rem 0 0 1rem;
    }

    @media screen and (max-width: 450px) {
      margin: 3.5rem 0 0 1rem;
    }

    @media screen and (max-width: 380px) {
      margin: 1.5rem 0 0 1rem;
    }
  }

  p {
    font-weight: 500;

    @media screen and (max-width: 800px) {
      color: black;
      margin-top: 3rem;
    }

    @media screen and (max-width: 550px) {
      font-size: 14px;
    }

    @media screen and (max-width: 450px) {
      margin-top: 3.5rem;
    }

    @media screen and (max-width: 380px) {
      margin-top: 4rem;
    }
  }

  h3 {
    @media screen and (max-width: 800px) {
      font-size: 2.3rem;
    }

    @media screen and (max-width: 550px) {
      font-size: 2rem;
    }

    @media screen and (max-width: 450px) {
      font-size: 1.7rem;
    }
  }
}

// --------- CONTACT - PROPOSAl ---------------
.getInvolved__contact {
  display: flex;
  // align-items: flex-start;
  // justify-content: flex-start;
  flex-direction: column;

  padding: 4rem 6rem;

  @media screen and (max-width: 1150px) {
    padding: 4rem;
  }
  @media screen and (max-width: 800px) {
    padding: 3rem 2rem;
  }
  @media screen and (max-width: 550px) {
    padding: 0 1rem 2rem;
  }
  @media screen and (max-width: 380px) {
    padding: 2rem 1rem;
  }

  .getInvolved__head {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 0 0 4rem;

    .head-line {
      width: 80px;
      height: 2px;
      background-color: #00877c;
    }
  }
}

// ------------ CONTACT -----------
.contact-info {
  // display: flex;
  // align-items: flex-start;
  // justify-content: flex-start;
  // flex-direction: column;

  h4 {
    margin-bottom: 1.5rem;

    @media screen and (max-width: 450px) {
      margin-bottom: 1rem;
      font-size: 1.4rem;
    }
  }

  h5 {
    margin-top: 1rem;

    @media screen and (max-width: 450px) {
      margin-top: 8px;
      font-size: 1.1rem;
    }
  }

  small {
    padding: 0.5rem 0;

    @media screen and (max-width: 450px) {
      font-size: 13px;
    }
  }
}

.join {
  @media screen and (max-width: 1150px) {
    padding: 2rem 0 8px;
  }
}

.links {
  display: flex;
  flex-direction: row;

  .link {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;

    @media screen and (max-width: 800px) {
      padding: 0;
    }

    svg {
      margin: 0 1rem 1.5rem 0;
      font-size: 1.5rem;
      color: #00877c;
      cursor: pointer;
      border-radius: 50%;
      // background: none;

      &:hover {
        color: #007a70;
        background-color: #ffff;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
      }

      @media screen and (max-width: 550px) {
        font-size: 1.3rem;
      }
    }
  }
}

.contact {
  display: flex;
  flex-direction: column;

  .link {
    // display: flex;
    // flex-direction: row;
    // align-items: center;
    // justify-content: flex-start;

    padding: 8px 0;

    svg {
      font-size: 1.5rem;
      margin-right: 12px;

      @media screen and (max-width: 550px) {
        font-size: 1.3rem;
      }
    }

    span {
      @media screen and (max-width: 550px) {
        font-size: 14px;
      }
    }
  }

  ul {
    padding-left: 3rem;

    li {
      @media screen and (max-width: 550px) {
        font-size: 14px;
      }
    }
  }
}

.btn_custom {
  background-color: #00877c;

  &:hover {
    background-color: #007d73;
  }

  &:active {
    background-color: #007d73;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  }
}

.contact_map {
  width: 100%;

  @media screen and (max-width: 1150px) {
    padding-top: 4rem;
  }

  @media screen and (max-width: 550px) {
    padding: 2rem 2rem 2rem 1rem;
  }

  iframe {
    width: 500px;

    @media screen and (max-width: 570px) {
      width: auto;
      height: 300px;
    }
  }
}

.scroll-btn {
  position: fixed;
  width: 100%;
  left: 93%;
  bottom: 60px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: rgba(128, 0, 128, 0.772);

  @media screen and (max-width: 750px) {
    font-size: 2.5rem;
    left: 92%;
  }

  @media screen and (max-width: 550px) {
    font-size: 2.1rem;
    left: 86%;
  }
}

.hr {
  display: flex;
  justify-content: center;

  div {
    width: 80%;
    height: 1px;
    background-color: rgb(211, 211, 211);
  }
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  text-align: center;

  @media screen and (max-width: 550px) {
    padding: 1rem 1.5rem;
  }
  @media screen and (max-width: 550px) {
    padding: 1rem;
  }

  small {
    font-size: 13px;
    color: gray;

    @media screen and (max-width: 550px) {
      font-size: 11px;
    }
  }

  .adil-link {
    color: #00877c;
    font-weight: 600;
  }
}

.form__after-submit {
  // flex: 1;
  width: 100%;
  // height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;
  box-shadow: -5px 5px 20px 5px #8888;

  @media screen and (max-width: 550px) {
    // height: 30vh;
    padding: 0;
  }
  @media screen and (max-width: 380px) {
    height: 30vh;
    padding: 1rem 0;
  }

  svg {
    font-size: 7rem;
    color: green;
    margin-bottom: 2rem;

    @media screen and (max-width: 450px) {
      font-size: 3rem;
    }
    @media screen and (max-width: 380px) {
      font-size: 5rem;
    }
  }

  h4 {
    text-align: center;

    @media screen and (max-width: 1150px) {
      font-size: 1.4rem;
      padding: 0 1rem;
    }
    @media screen and (max-width: 380px) {
      font-size: 1.3rem;
    }

    &:first-child {
      @media screen and (max-width: 380px) {
        width: 100%;
        font-size: 1rem;
        padding: 0;
        margin: 0;
        line-height: 1.5rem;
      }
    }
  }
}
